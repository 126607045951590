<template>
  <auth>
    <div class="right-content">
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span>
          <div class="brand-name"><span>Co-op</span><span>Bid</span></div>
        </div>
        <h4 class="register-header">Email verification</h4>
        <div class="icon">
          <i class="fa fa-sign-in"></i>
        </div>
      </div>
      <div v-if="error.length" class="alert alert-danger-verify">
        {{ error }}
      </div>
      <div v-html="$error.handle(error)" />
      <loading v-if="loading" />
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      error: "",
      loading: true,
    };
  },
  mounted() {
    if (this.$store.getters.getAuthUser.verified_email) {
      this.$router.push({ name: "Login", query: { a: 1 } });
    } else {
      this.verify();
    }
  },
  methods: {
    verify() {
      this.$axios
        .post("/api/v1/email/activate", {
          code: this.$route.params.code,
        })
        .then(() => {
          this.$router.push({ name: "Login", query: { a: 1 } });
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.error;
        });
    },
  },
};
</script>
